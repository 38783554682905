import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { types as sdkTypes } from '../../util/sdkLoader';
const { LatLngBounds, LatLng } = sdkTypes;

import IconClose from '../IconClose/IconClose';
import css from './AlgoliaLocationSearch.module.css';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import { getLocationWithMapBox } from '../../util/api';
import { useDispatch, useSelector } from 'react-redux';
import { currentUserSelector, setCUserCurrencyAndLocation } from '../../ducks/user.duck';
import { getUserDefaultCurrency } from '../../util/data';
import { supportedCountries } from '../../config/configStripe';
import { isAuthenticatedSelector } from '../../ducks/auth.duck';

const mapboxToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const PREDICTIONS_KEY = 'predictions';
const MAX_PREDICTIONS = 3;
const DEFAULT_LOCATION = 'Ottawa';
const DEFAULT_LOCATION_ERROR = "Please select your search area."

const getCityFromMapBoxLocation = data => {
  const { features = [] } = data || {};
  if (!features.length) {
    return null;
  }

  const addressObj = features.find(d => d.id.startsWith('address'));
  const pointObj = features.find(d => d.id.startsWith('poi'));
  const localityObj = features.find(d => d.id.startsWith('locality'));
  const address = !!pointObj
    ? pointObj
    : !!addressObj
    ? addressObj
    : !!localityObj
    ? localityObj
    : null;

  if (!address) {
    return null;
  }

  const { place_name, context } = address;
  const locationInfo = context.reduce((info, item) => {
    const { id, text } = item;
    const name = id.split('.')[0];

    if (['country', 'place', 'district', 'postcode'].includes(name)) {
      info[name] = text;
    }

    return info;
  }, {});

  const { place } = locationInfo;

  return place;
};

/**
 * store prediction to local host
 * @param {*} data
 */
const storePredictions = prediction => {
  if (!prediction || !prediction.id || typeof window === 'undefined') {
    return;
  }

  const storedPredictions = window.localStorage.getItem(PREDICTIONS_KEY);
  const parsedPredictions = storedPredictions
    ? JSON.parse(storedPredictions)
    : [];

  // Use a Map for uniqueness and easy manipulation
  const predictionsMap = new Map(
    parsedPredictions.map(pred => [pred.id, pred])
  );

  // Add new prediction at the beginning
  predictionsMap.delete(prediction.id); // Ensure no duplicates
  const newPredictions = [prediction, ...Array.from(predictionsMap.values())];

  // Keep only the first MAX_PREDICTIONS entries
  const limitedPredictions = newPredictions.slice(0, MAX_PREDICTIONS);

  // Store updated predictions in localStorage
  window.localStorage.setItem(
    PREDICTIONS_KEY,
    JSON.stringify(limitedPredictions)
  );
};

/**
 * Get predictions from the local storage
 * @returns predictions array
 */
const getPredictions = () => {
  if (typeof window === 'undefined') {
    return [];
  }

  const storedPredictions = window.localStorage.getItem(PREDICTIONS_KEY);
  return storedPredictions ? JSON.parse(storedPredictions) : [];
};

const fetchAndApplyPrediction = async (locationName, handlePredictionClick) => {
  const mapBoxResponse = await axios.get(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${locationName}.json?access_token=${mapboxToken}`
  );

  const prediction = mapBoxResponse?.data?.features?.[0];
  if (!prediction) {
    throw 'Invalid prediction';
  }

  return handlePredictionClick(prediction);
};

const useIPBasedLocation = async ({
  setInputValue,
  setInputFocused,
  setError,
  handlePredictionClick,
}) => {
  try {
    const response = await axios.get('https://ipapi.co/json/');
    const { city, country_name: country } = response.data;

    if (!city) {
      throw 'Invalid city';
    }

    setInputValue(city);
    setInputFocused(false);

    await fetchAndApplyPrediction(city, handlePredictionClick);
  } catch (error) {
    setError(DEFAULT_LOCATION_ERROR);
  }
};

const LocationSearch = props => {
  const {
    defaultLocation,
    name,
    initialValues,
    onRefineLocation,
    className,
    setLocation = () => {},
    isLandingPage = false
  } = props;
  const dispatch = useDispatch();
  const currentUser = useSelector(currentUserSelector);
  const [inputValue, setInputValue] = useState('');
  const [predictions, setPredictions] = useState([]);
  const [error, setError] = useState('');
  const [inputFocused, setInputFocused] = useState(false);
  const blurTimeout = useRef(null);
  const dropdownRef = useRef(null);
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  const storedPredictions = getPredictions();
  const renderablePredictions =
    predictions && predictions.length > 0
      ? predictions
      : storedPredictions && storedPredictions.length > 0
        ? storedPredictions
        : [];

  // Show predictions only if input is in focused.
  const showPredictions = inputFocused;

  useEffect(() => {
    const userLocation = Cookies.get('userLocation');
    if (userLocation) {
      setInputValue(userLocation);
      setInputFocused(false);
      fetchAndApplyPrediction(userLocation, handlePredictionClick);
      return;
    }

    if (!navigator.geolocation) {
      return useIPBasedLocation({
        setInputValue,
        setInputFocused,
        setError: setInputValue,
        handlePredictionClick,
      });
    }

    navigator.geolocation.getCurrentPosition(
      async position => {
        try {
          const { longitude, latitude } = position.coords;

          const data = await getLocationWithMapBox(longitude, latitude);
          const place = getCityFromMapBoxLocation(data);

          if (!place) {
            throw new Error('No locations found!');
          }

          setInputValue(place);
          setInputFocused(false);
          return await fetchAndApplyPrediction(place, handlePredictionClick);
        } catch (err) {
          return await useIPBasedLocation({setInputValue, setInputFocused, setError: setInputValue, handlePredictionClick})
        }
      },
      async err => {
        return await useIPBasedLocation({setInputValue, setInputFocused, setError: setInputValue, handlePredictionClick});
      }
    );
  }, []);

  useEffect(() => {
    if (inputValue) {
      fetchPredictions(inputValue);
    } else {
      setPredictions([]);
    }
  }, [inputValue]);

  const fetchPredictions = async query => {
    try {
      const response = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${mapboxToken}`
      );
      setPredictions(response.data.features);
    } catch (error) {
      console.error('Error fetching predictions:', error);
    }
  };

  const handleInputChange = e => {
    setInputFocused(true);
    setInputValue(e.target.value);

    setError('');
  };

  const handleInputBlur = () => {
    // Delay input blur for some time so
    // user can select value from the recommendations.
    blurTimeout.current = setTimeout(() => {
      // if (!selectedPrediction) {
      //   setError('Please select from the suggested locations');
      // }
      setInputFocused(false);
    }, 500);
  };

  const handlePredictionClick = (prediction, isPredictionClicked = false) => {
    if (blurTimeout && blurTimeout.current) {
      clearTimeout(blurTimeout.current);
    }

    const { bbox = [], center = [] } = prediction || {};

    // swLng (southwest longitude), swLat (southwest latitude)
    // neLng (northeast longitude), neLat (northeast latitude)
    const [swLng, swLat, neLng, neLat] = bbox;

    const kmToDegLat = 50 / 111; // Latitude degree conversion
    const kmToDegLng = 50 / (111 * Math.cos((swLat + neLat) / 2 * Math.PI / 180)); // Longitude degree conversion

    // Expand the bbox by 50km
    const expandedBounds = bbox && bbox.length === 4
      ? [
          swLng - kmToDegLng, // Expanded swLng
          swLat - kmToDegLat, // Expanded swLat
          neLng + kmToDegLng, // Expanded neLng
          neLat + kmToDegLat  // Expanded neLat
        ]
      : null;

    // Construct bounds around center if bbox is missing
    const constructedBounds =
      center && center.length === 2
        ? [
          center[0] - kmToDegLng, // swLng
          center[1] - kmToDegLat, // swLat
          center[0] + kmToDegLng, // neLng
          center[1] + kmToDegLat, // neLat
        ]
        : null;

    let selectedBounds =
      expandedBounds
        ? {
            southWest: { lng: expandedBounds[0], lat: expandedBounds[1] },
            northEast: { lng: expandedBounds[2], lat: expandedBounds[3] },
          }
        : constructedBounds
          ? {
              southWest: { lng: constructedBounds[0], lat: constructedBounds[1] },
              northEast: { lng: constructedBounds[2], lat: constructedBounds[3] },
            }
          : null;

    if (prediction && selectedBounds) {
      setInputValue(prediction.place_name);
      setError('');
      setInputFocused(false);

      const { southWest, northEast } = selectedBounds;
      const swLatLng = new LatLng(southWest.lat, southWest.lng);
      const neLatLng = new LatLng(northEast.lat, northEast.lng);
      const latlngBounds = new LatLngBounds(neLatLng, swLatLng);

      const latLng = new LatLng(center[1], center[0]);

      const payload = {
        address: prediction.place_name,
        bounds: latlngBounds,
        origin: latLng,
      };

      /*
        since this is a shared component, we don't want to trigger onRefineLocation
        from the homepage unless some prediction is clicked.
      */
      if (isLandingPage && !isPredictionClicked) {
        return setLocation(payload);
      }

      onRefineLocation({
        ...payload,
        isLandingPage
      });

      // since the location is entered manually, we need to store and preserve it in the cookies for 30 days
      if (isPredictionClicked) {
        Cookies.set('userLocation', prediction.place_name, {
          expires: 30,
          secure: true,
        });

        if (!isAuthenticated) {
          const currency = getUserDefaultCurrency(
            supportedCountries.find(({ country }) =>
              prediction.place_name.includes(country)
            )?.code ?? 'US'
          );

          Cookies.set('userCurrency', currency, {
            expires: 30,
            secure: true,
          });
        }

        dispatch(
          setCUserCurrencyAndLocation(currentUser, prediction.place_name)
        );
      }

      // Store prediction to local
      storePredictions(prediction);
    }
  };

  const handleMouseEnter = index => {
    const items = dropdownRef.current.querySelectorAll('li');
    items.forEach((item, idx) => {
      item.classList.toggle(css.highlighted, idx === index);
    });
  };

  return (
    <div className={classNames(className, css.container)}>
      <div className={css.inputWrapper}>
        <input
          type="text"
          name={name}
          value={inputValue}
          onFocus={() => setInputFocused(true)}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          className={inputValue == DEFAULT_LOCATION_ERROR ? css.inputError : css.input}
          placeholder="Enter a location"
        />
        {!!inputValue ? (
          <button
            className={css.clearLocation}
            type="button"
            onClick={() => {
              setInputValue('');

              setError('');
            }}
          >
            <IconClose size="small" />
          </button>
        ) : null}
      </div>
      {error && <div className={css.error}>{error}</div>}
      {renderablePredictions.length > 0 && showPredictions && (
        <ul className={css.dropdown} ref={dropdownRef}>
          {renderablePredictions.map((prediction, index) => (
            <li
              key={prediction.id}
              onClick={() => handlePredictionClick(prediction, true)}
              onMouseEnter={() => handleMouseEnter(index)}
              className={css.dropdownItem}
            >
              {prediction.place_name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LocationSearch;
